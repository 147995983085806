import Vue from "vue";
import Vuex from "vuex";
import router from "./router.js";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		connected: false,
		user: {},
	},
	mutations: {
		setUser(state, payload) {
			state.connected = true;
			state.user = payload;
		},
		removeUser(state) {
			state.connected = false;
			state.user = {};
		},
	},
	actions: {
		deconnexion({ state, commit }) {
			commit("removeUser");
			router.push({ name: "Connexion" });
		},
	},
	getters: {
		user: (state) => {
			return state.user;
		},
	},
	modules: {},
	plugins: [createPersistedState()],
});
