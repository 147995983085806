<template>
      <div id="logo">
            <a href="https://www.ambpicot.com" target="_blank">
                  <img src="../assets/logo@2x.png" title="AMB PICOT" alt="AMB PICOT" />
            </a>
      </div>
</template>
<script>
      export default {
            name: 'LogoComponent',
            components: {},
            data() {
                  return {}
            },
            methods: {},
      }
</script>

<style lang="scss">
#logo {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 99;
      img {
            width: 120px;
            height: auto;
            @media screen and (max-width: 600px) {
                  width: 100px;
            }
      }
}
</style>