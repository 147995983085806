import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "./views/dashboard.vue";
import store from "./store.js";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Dashboard",
		component: Dashboard
	},
	{
		path: "/connexion",
		name: "Connexion",
		component: () => import(/* webpackChunkName: "connexion" */ "./views/connexion.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});


router.beforeEach((to, from, next) => {
	if (store.state.connected === false) {
		if (to.name != "Connexion") {
			next({name: "Connexion"})
		} else {
			next();
		}
	} else {
		if (to.name === "Connexion") {
			next({ name: "Dashboard" });
		} else {
			next();
		}
	}
})
export default router;
