<template>
     <div class="bottom">
            © AMBPICOT - All rights reserved - Version {{ version }}
      </div>
</template>
<script>
import { version } from "../../package.json";
      export default {
            name: 'BottomFooter',
            components: {},
            data() {
                  return {
                        version: version
                  }
            },
            methods: {},
      }
</script>

<style lang="scss">
@import '../assets/styles/variables.scss';
.bottom {
      position: relative;
      &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 380px;
            height: 1px;
            background: $orange;
            right: 0;
            @media screen and (max-width: 768px) {
                  left: 30px;
                  right: 30px;
            }
      }
      background: white;
      padding: 20px 50px 100px 380px;
      color: $orange;
      font-weight: 300;
      font-size: 12px;
      @media screen and (max-width: 768px) {
            padding: 30px;
      }
}
</style>